import i18n from 'i18n'
import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { BrowserRouter } from 'react-router-dom'
import App from './app'
import './axiosInterceptor'
import AppProviders from './context/appProviders'
import './i18n'
import GlobalStyles from './styles/globalStyles'
const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6Ld6xAsoAAAAADWfjo5E7f0It4wUbElIbHipuBCo"
    language={i18n.language}
    scriptProps={{
      async: false,
      defer: false,
      appendTo: 'head',
      nonce: undefined
    }}
    // useEnterprise
  >
    <StrictMode>
      <React.Suspense fallback={<div tw="w-full h-full bg-gray-900" />}>
        <BrowserRouter>
          <AppProviders>
            <GlobalStyles />
            <App />
          </AppProviders>
        </BrowserRouter>
      </React.Suspense>
    </StrictMode>
  </GoogleReCaptchaProvider>
)
