import axios from 'axios'
import i18next from 'i18next'

const apiErrorsTranslations: Record<string, string> = {}

const errorMessagesKeys: string[] = ['UnexpectedError']

function updateTranslations() {
  axios.defaults.headers.common.language = localStorage.getItem('i18nextLng')?.slice(0, 2) || 'pl'
  errorMessagesKeys.forEach(
    (key) => (apiErrorsTranslations[key] = i18next.t(`common:apiResponse.${key}`))
  )
}

i18next.on('languageChanged', () => {
  updateTranslations()
})

i18next.on('loaded', () => {
  updateTranslations()
})

export { apiErrorsTranslations }
