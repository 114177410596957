import {
  faApple,
  faFacebook,
  faGoogle,
  faInstagram,
  faTiktok,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'

import {
  faAcorn,
  faAlien,
  faAmbulance,
  faArrowRightArrowLeft,
  faArrowRightFromBracket,
  faAtom,
  faBaby,
  faBabyCarriage,
  faBadgeCheck,
  faBars,
  faBarsSort,
  faBaseballBall,
  faBath,
  faBed,
  faBeer,
  faBell,
  faBiking,
  faBirthdayCake,
  faBolt,
  faBomb,
  faBone,
  faBook,
  faBookmark,
  faBowlingBall,
  faBox,
  faBreadSlice,
  faBriefcaseMedical,
  faBroom,
  faCake,
  faCalendarDays,
  faCamera,
  faCar,
  faCarSide,
  faCartShopping,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChild,
  faCircleInfo,
  faCirclePlay,
  faCircleStop,
  faCircleXmark,
  faClock,
  faClockRotateLeft,
  faCoins,
  faCompress,
  faCrop,
  faExpand,
  faEye,
  faEyeSlash,
  faFemale,
  faGamepadAlt,
  faGift,
  faHeart,
  faHome,
  faHomeBlank,
  faHouse,
  faLaptop,
  faLaughBeam,
  faLips,
  faLockKeyhole,
  faMagnifyingGlass,
  faMailbox,
  faMars,
  faMasksTheater,
  faMoneyBill1Wave,
  faMonkey,
  faMugHot,
  faPalette,
  faPaw,
  faPenToSquare,
  faPlay,
  faPlus,
  faSearch,
  faShareNodes,
  faSoup,
  faSpaceShuttle,
  faTrash,
  faTrashCan,
  faTruckFast,
  faTshirt,
  faUserChart,
  faUserLarge,
  faUsers,
  faVenus,
  faVideo,
  faVolleyballBall,
  faXmark
} from '@fortawesome/pro-regular-svg-icons'

import {
  faClock as faClockFilled,
  faHeart as faHeartFilled
} from '@fortawesome/pro-solid-svg-icons'
import { faChartSimple } from '@fortawesome/sharp-solid-svg-icons'

export const ICONS = {
  spaceShuttle: faSpaceShuttle,
  bowlingBall: faBowlingBall,
  'birthday-cake': faBirthdayCake,
  cake: faCake,
  broom: faBroom,
  female: faFemale,
  book: faBook,
  acorn: faAcorn,
  alien: faAlien,
  breadSlice: faBreadSlice,
  monkey: faMonkey,
  beer: faBeer,
  bone: faBone,
  bomb: faBomb,
  atom: faAtom,
  ambulance: faAmbulance,
  bath: faBath,
  bed: faBed,
  home: faHome,
  users: faUsers,
  box: faBox,
  man: faMars,
  woman: faVenus,
  child: faChild,
  clock: faClock,
  'clock-filled': faClockFilled,
  'user-large': faUserLarge,
  bars: faBars,
  bell: faBell,
  'cart-shopping': faCartShopping,
  play: faPlay,
  'baby-carriage': faBabyCarriage,
  'basketball-ball': faBaseballBall,
  camera: faCamera,
  'chevron-right': faChevronRight,
  'chevron-left': faChevronLeft,
  'chevron-up': faChevronUp,
  'chevron-down': faChevronDown,
  check: faCheck,
  'calendar-days': faCalendarDays,
  eye: faEye,
  'eye-slash': faEyeSlash,
  'magnifying-glass': faMagnifyingGlass,
  'circle-play': faCirclePlay,
  'circle-stop': faCircleStop,
  plus: faPlus,
  'share-nodes': faShareNodes,
  'clock-rotate-left': faClockRotateLeft,
  xmark: faXmark,
  'circle-xmark': faCircleXmark,
  expand: faExpand,
  compress: faCompress,
  heart: faHeart,
  'heart-filled': faHeartFilled,
  facebook: faFacebook,
  instagram: faInstagram,
  apple: faApple,
  google: faGoogle,
  youTube: faYoutube,
  tikTok: faTiktok,
  'badge-check': faBadgeCheck,
  'mug-hot': faMugHot,
  search: faSearch,
  laptop: faLaptop,
  tshirt: faTshirt,
  'home-blank': faHomeBlank,
  baby: faBaby,
  lips: faLips,
  'briefcase-medical': faBriefcaseMedical,
  'masks-theater': faMasksTheater,
  'volleyball-ball': faVolleyballBall,
  'car-side': faCarSide,
  palette: faPalette,
  'pen-to-square': faPenToSquare,
  trash: faTrash,
  'circle-info': faCircleInfo,
  video: faVideo,
  'lock-keyhole': faLockKeyhole,
  'arrow-right-from-bracket': faArrowRightFromBracket,
  'bars-sort': faBarsSort,
  car: faCar,
  bolt: faBolt,
  gift: faGift,
  house: faHouse,
  paw: faPaw,
  soup: faSoup,
  'gamepad-alt': faGamepadAlt,
  'laugh-beam': faLaughBeam,
  'user-chart': faUserChart,
  biking: faBiking,
  gamepadAlt: faGamepadAlt,
  laugBeam: faLaughBeam,
  userChart: faUserChart,
  faTrashCan: faTrashCan,
  faArrowRightArrowLeft: faArrowRightArrowLeft,
  faTruckFast: faTruckFast,
  faMailbox: faMailbox,
  faCoins: faCoins,
  faCrop,
  faChartSimple,
  faBookmark,
  faMoneyBill1Wave
}
