import { MutableRefObject, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { Dialog } from '../dialogBase/dialog'
import { DialogRef } from '../dialogBase/dialogProvider'
import { ChangePasswordDialogContent } from './changePasswordDialog'
/** @jsxImportSource @emotion/react */

interface DialogProps {
  children?: ReactNode
  forwardedRef?: MutableRefObject<DialogRef>
}

const ChangePasswordButton = ({ children, forwardedRef }: DialogProps) => {
  const { t } = useTranslation('auth')
  return (
    <Dialog title={t('changePassword.title')} trigger={children} forwardedRef={forwardedRef}>
      <ChangePasswordDialogContent />
    </Dialog>
  )
}

export { ChangePasswordButton }
