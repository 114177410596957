// change configuration to https://github.com/benawad/dogehouse/blob/staging/kibbeh/src/lib/i18n.ts

import i18n from 'i18n'
import 'twin.macro'
import { theme } from 'twin.macro'
/** @jsxImportSource @emotion/react */

function convertUTCDateToLocalDate(date: Date) {
  const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
  const offset = date.getTimezoneOffset() / 60
  const hours = date.getHours()

  newDate.setHours(hours - offset)
  return newDate
}

const localeFormDate = (utcDate?: Date): string => {
  if (!utcDate) {
    return '-'
  }

  const converted = new Date(utcDate)
  const day = converted.getDate()
  const year = converted.getFullYear()
  const month = converted.getMonth() + 1
  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`
}

const localeDate = (utcDate?: Date, option?: Intl.DateTimeFormatOptions): string => {
  if (!utcDate) return '-'
  return new Date(utcDate).toLocaleString(localStorage.getItem('i18nextLng') || undefined, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    ...option
  })
}

const numberWithSpaces = (x?: number, isPrice?: boolean) => {
  if (!x) {
    return 0
  }

  const language = i18n.language
  const number = isPrice ? x.toFixed(2) : x

  return language === 'pl'
    ? number
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        .replace('.', ',')
    : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

const numberToLocal = (value?: number, minimumFractionDigits = 0) =>
  value?.toLocaleString(localStorage.getItem('i18nextLng') || undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits
  }) || 0

const toPascalCase = (string: string, splitBy = ' ') =>
  string
    .split(splitBy)
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join('')

const capitalize = (string: string) => string[0].toUpperCase() + string.substr(1).toLowerCase()

function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) {
    return '0 B'
  }

  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`
}

export type ColorVariant = 'white' | 'primary' | 'lightGray' | 'darkGray'

const getColor = (colorVariant: ColorVariant) => {
  switch (colorVariant) {
    case 'white':
      return theme`colors.gray.100`
    case 'lightGray':
      return theme`colors.gray.600`
    case 'darkGray':
      return theme`colors.gray.900`
    case 'primary':
      return theme`colors.primary`
    default:
  }
}

const validateMaxLengthWithReplaceValue = (value: string, maxLength: number) => {
  const valueEditReplace = value?.replace(/(?<!\r)\n/g, '\r\n') as string

  if (valueEditReplace.length > maxLength) {
    return 'maxLength'
  }

  return true
}

export {
  bytesToSize,
  capitalize,
  convertUTCDateToLocalDate,
  getColor,
  localeDate,
  localeFormDate,
  numberToLocal,
  numberWithSpaces,
  toPascalCase,
  validateMaxLengthWithReplaceValue
}
