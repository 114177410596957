import styled, { css } from 'styled-components'
import tw from 'twin.macro'
/** @jsxImportSource @emotion/react */

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'top' | 'right' | 'bottom' | 'left'
  color?: 'primary' | 'gray'
}

const Arrow = styled.div(({ variant = 'top', color = 'gray' }: ButtonProps) => [
  tw`absolute border-arrow border-transparent`,
  variant === 'left' &&
    css`
      left: 0;
      top: 50%;
      transform: translate(-100%, -50%);
      border-style: solid;
      border-left-width: 0;
      border-right-color: ${color === 'primary' ? '#46CF73' : '#484C52'};
    `,
  variant === 'right' &&
    css`
      right: 0;
      top: 50%;
      transform: translate(100%, -50%);
      border-style: solid;
      border-right-width: 0;
      border-left-color: ${color === 'primary' ? '#46CF73' : '#484C52'};
    `,
  variant === 'top' &&
    css`
      right: 50%;
      top: 0;
      transform: translate(50%, -100%);
      border-style: solid;
      border-top-width: 0;
      border-bottom-color: ${color === 'primary' ? '#46CF73' : '#484C52'};
    `,
  variant === 'bottom' &&
    css`
      right: 50%;
      bottom: 0;
      transform: translate(50%, 100%);
      border-style: solid;
      border-bottom-width: 0;
      border-top-color: ${color === 'primary' ? '#46CF73' : '#484C52'};
    `
])

export { Arrow }
