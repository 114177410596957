import { Button } from 'components/ui/button'
import { Spinner } from 'components/ui/spinner'
import i18n from 'i18n'
import { NotificationDTO } from 'models/api/notificationDTO'
import React, { LegacyRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  InfiniteData,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation
} from 'react-query'
/** @jsxImportSource @emotion/react */
import * as timeago from 'timeago.js'
import en from 'timeago.js/lib/lang/en_US'
import pl from 'timeago.js/lib/lang/pl'
import 'twin.macro'
import { api } from 'utils/apiClient'
import { NotificationItem } from './notificationItem'

timeago.register('pl', pl)
timeago.register('en', en)

interface NotificationMenuProps {
  setIsOpenNotificationMenu: React.Dispatch<React.SetStateAction<boolean>>
  isOpenNotificationMenu: boolean
  containerRef?: LegacyRef<HTMLDivElement>
  data?: NotificationDTO[]
  isCompany: boolean
  isLoading?: boolean
  onScroll: () => void
  refetchNotifications: (
    options?: (RefetchOptions & RefetchQueryFilters<unknown>) | undefined
  ) => Promise<QueryObserverResult<InfiniteData<NotificationDTO>, unknown>>
}

const NotificationMenu = ({
  data,
  isLoading = false,
  refetchNotifications,
  containerRef,
  onScroll,
  isCompany
}: NotificationMenuProps) => {
  const { t } = useTranslation('common')
  const { language } = i18n

  const markAsReadMutation = useMutation(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ id, index }: { id: number; index: number }) => {
      return api.put(`notification/${id}/mark-as-read`)
    },
    {
      onSuccess: (c, variables) => {
        const getPageItem = Math.floor(variables.index / 25)
        refetchNotifications({
          refetchPage: (page, index) => {
            return index === getPageItem
          }
        })
      }
    }
  )

  const markAllAsReadMutation = useMutation(
    () => {
      return api.put(`notification/${isCompany ? 'company' : 'creator'}/mark-all-as-read`)
    },
    {
      onSuccess: () => {
        refetchNotifications()
      }
    }
  )

  const handleClearNotifications = () => {
    markAllAsReadMutation.mutate()
  }

  return (
    <div
      tw="absolute max-h-[calc(100vh - 56px)] xl:max-h-[400px] overflow-y-scroll overflow-x-hidden bg-gray-500 xl:max-w-[40%] max-w-[70%]  z-20 shadow-2xl top-0 right-6 transform my-14 xl:my-16 flex flex-col animate-fade-in-slow items-start space-y-1 py-3 rounded-md mr-[-22px]"
      ref={containerRef}
      onScroll={onScroll}
    >
      <div tw="flex flex-col items-center">
        {!!data?.length && (
          <div tw="pb-2 px-8">
            <Button onClick={handleClearNotifications} isLoading={markAllAsReadMutation.isLoading}>
              {t(`buttons.clearNotifications`)}
            </Button>
          </div>
        )}
        {isLoading && <Spinner isAbsolute={false} />}
        {!isLoading &&
          data?.map((notification, index) => (
            <NotificationItem
              id={notification.notificationId}
              key={index}
              howLongAgo={timeago.format(notification.addedAt, language)}
              type={notification.type}
              isRead={notification.isRead}
              index={index}
              text={t(`notifications.${notification.type}`, { ...notification })}
              markAsReadMutation={markAsReadMutation}
            />
          ))}
        {!isLoading && data?.length === 0 && (
          <p tw="pt-2 text-xs px-4 text-center">{t('info.noNotifications')}</p>
        )}
      </div>
    </div>
  )
}

export { NotificationMenu }
