/** @jsxImportSource @emotion/react */
import {
  faBan,
  faBox,
  faCartShopping,
  faEnvelope,
  faFileCheck,
  faFileXmark,
  faFilm,
  faFilmSlash,
  faHeart,
  faPlus,
  faStar
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosResponse } from 'axios'
import { NotificationTypeEnum } from 'models/api/notificationTypeEnum'
import { UseMutationResult } from 'react-query'
import { useNavigate } from 'react-router-dom'
import 'twin.macro'
import tw from 'twin.macro'

interface NotificationItemProps {
  text: string
  howLongAgo: string
  isRead: boolean
  index: number
  type: NotificationTypeEnum
  markAsReadMutation: UseMutationResult<
    AxiosResponse<any>,
    unknown,
    {
      id: number
      index: number
    },
    unknown
  >
  id: number
}

const notificationsData = {
  videoAccepted: {
    icon: faFilm,
    url: '/video'
  },
  videoRejected: { icon: faFilmSlash, url: '/video' },
  videoBoughtThroughtCreatorMovie: { icon: faCartShopping, url: '/video' },
  videoBoughtThroughtCompany: { icon: faCartShopping, url: '' },
  addedToWishListCreator: { icon: faStar, url: '/video' },
  addedToWishListCompany: { icon: faStar, url: '/creator' },
  creatorLiked: { icon: faHeart, url: '' },
  creatorRequest: { icon: faStar, url: '/creator' },
  companyRequestAccepted: { icon: faFileCheck, url: '' },
  companyRequestRejected: { icon: faFileXmark, url: '' },
  addedToWishlist: { icon: faStar, url: '' },
  companyRequest: { icon: faEnvelope, url: '' },
  videoBought: { icon: faCartShopping, url: '/creator' },
  videoReportBlockCreator: { icon: faBan, url: '/video' },
  videoReportBlockSeller: { icon: faBan, url: '/video' },
  newVideoNotificationForFan: {
    icon: faPlus,
    url: ''
  },
  newVideoNotificationForCompany: {
    icon: faPlus,
    url: ''
  },
  productAlmostSold: {
    icon: faBox,
    url: '/product'
  },
  productSold: {
    icon: faBox,
    url: '/product'
  }
}

const NotificationItem = ({
  text,
  howLongAgo,
  isRead,
  index,
  type,
  markAsReadMutation,
  id
}: NotificationItemProps) => {
  const history = useNavigate()
  const handleOnClick = () => {
    if (!isRead) markAsReadMutation.mutate({ id: id, index: index })
    const url = notificationsData[type]?.url
    if (url) history(url)
  }
  return (
    <button
      onClick={handleOnClick}
      css={[index % 2 === 0 && tw`bg-[#404348]`]}
      tw="justify-self-start self-start flex w-full items-center px-4 py-2 text-xs font-bold "
    >
      <FontAwesomeIcon icon={notificationsData[type].icon} tw="h-4 mr-4 w-4 min-w-4 min-h-4" />
      <div tw="flex flex-col justify-start items-start pr-2">
        <h1 tw="font-bold  text-left text-xs">{text}</h1>
        <p tw="text-left text-gray-200 text-xs">{howLongAgo}</p>
      </div>
      {!isRead && (
        <div tw="flex ml-auto text-right text-3xl  w-2 h-2 min-w-2 min-h-2 bg-primary rounded"></div>
      )}
    </button>
  )
}

export { NotificationItem }
