import { IconProp } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRightFromBracket,
  faArrowsRotate,
  faLockKeyhole
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangePasswordButton } from 'components/dialog/changePasswordDialog/changePasswordButton'
import { LogoutDialog } from 'components/dialog/logoutDialog/logoutDialog'
import { useAuth } from 'context/authProvider'
import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'
import tw from 'twin.macro'
import { navigationList } from '../sideBar/sideBar'
/** @jsxImportSource @emotion/react */

interface MobileMenuProps {
  handleCloseMobileMenu: () => void
  isOpenMobileMenu: boolean
  profilePhoto?: string
  pathname?: string
}

const MobileMenu = ({
  handleCloseMobileMenu,
  isOpenMobileMenu,
  profilePhoto,
  pathname
}: MobileMenuProps) => {
  const { t } = useTranslation('common')
  const { t: tProfile } = useTranslation('profile')
  const { user } = useAuth()
  const userType =
    user?.userType === 'creatorAndCompany'
      ? window.localStorage.getItem('userType')
      : user?.userType

  const isViewEditProfileNav =
    user?.companyStatus == 'active' ||
    user?.companyStatus == 'stripeActivated' ||
    user?.creatorStatus != 'none'

  const isViewButtonChangeRole = user?.userType == 'fan'

  return (
    <div
      css={[
        tw`xl:hidden z-50 w-full h-screen fixed top-14 left-0 bg-gray-900 overflow-auto h-nav-screen transform transition-transform  -translate-x-full`,
        isOpenMobileMenu && tw`translate-x-0`
      ]}
    >
      {isViewEditProfileNav && (
        <Link to="profile" onClick={handleCloseMobileMenu} css={sharedStyled}>
          <img
            src={
              user?.creatorStatus === 'active'
                ? profilePhoto
                : '/images/placeholders/profile-photo.png'
            }
            tw="rounded-full"
            width="40"
            height="40"
            alt="thumbnail"
          />

          <div tw="text-xs font-semibold ml-2">
            <p>{tProfile(`creator.yourProfile`)}</p>
            <p>{user?.email}</p>
          </div>
        </Link>
      )}

      {(userType === 'creator' || (userType === 'company' && user?.companyStatus === 'active')) &&
        navigationList.map(
          ({ icon, path, type }, index) =>
            type.find((item) => userType === item) && (
              <NavLink
                key={index}
                to={path}
                onClick={handleCloseMobileMenu}
                css={sharedStyled}
                style={({ isActive }) => ({
                  backgroundColor: (isActive && '#46cf734f') || '',
                  borderTopWidth: (isActive && '1px') || '',
                  borderColor: (isActive && '#46CF73') || '',
                  color: (isActive && '#F5F5F5') || ''
                })}
              >
                {icon && <FontAwesomeIcon icon={icon as IconProp} tw="h-4 mr-2" />}
                <h1 tw="font-semibold">{t(`navigation.${path}` as never)}</h1>
              </NavLink>
            )
        )}

      {userType === 'creator' && (
        <NavLink
          to={'/bonuses'}
          onClick={handleCloseMobileMenu}
          css={sharedStyled}
          style={({ isActive }) => ({
            backgroundColor: (isActive && '#46cf734f') || '',
            borderTopWidth: (isActive && '1px') || '',
            borderColor: (isActive && '#46CF73') || '',
            color: (isActive && '#F5F5F5') || ''
          })}
        >
          {location.pathname.includes('bonuses') ? (
            <img src="/images/icons/bonuses-green.svg" tw="w-4" alt="bonuses-green" />
          ) : (
            <img src="/images/icons/bonuses.svg" tw="w-4" alt="bonuses" />
          )}
          <h1 tw="font-semibold ml-2">{t(`navigation.bonuses` as never)}</h1>
        </NavLink>
      )}

      {isViewButtonChangeRole && (
        <NavLink
          to={'change-role'}
          onClick={handleCloseMobileMenu}
          css={sharedStyled}
          style={({ isActive }) => ({
            backgroundColor: (isActive && '#46cf734f') || '',
            borderTopWidth: (isActive && '1px') || '',
            borderColor: (isActive && '#46CF73') || '',
            color: (isActive && '#F5F5F5') || ''
          })}
        >
          <FontAwesomeIcon icon={faArrowsRotate} tw="h-4 mr-2" />
          <h1 tw="font-semibold">{t(`navigation.change-role`)}</h1>
        </NavLink>
      )}

      <ChangePasswordButton>
        <button css={sharedStyled}>
          <FontAwesomeIcon tw="h-4 mr-2" icon={faLockKeyhole} />
          {t('navigation.changePassword')}
        </button>
      </ChangePasswordButton>
      <LogoutDialog>
        <button onClick={handleCloseMobileMenu} css={sharedStyled}>
          <FontAwesomeIcon tw="h-4 mr-2" icon={faArrowRightFromBracket} />
          {t('navigation.logout')}
        </button>
      </LogoutDialog>
    </div>
  )
}

const sharedStyled = tw`px-8 py-4 border-b border-gray-500 bg-gray-900 text-gray-100 flex w-full items-center font-semibold`

export default MobileMenu
