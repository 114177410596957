import { MutableRefObject, ReactNode } from 'react'
import 'twin.macro'
import { DialogContents, DialogSizes } from './dialogContents'
import { DialogOpenButton } from './dialogOpenButton'
import { DialogProvider, DialogRef } from './dialogProvider'
/** @jsxImportSource @emotion/react */

interface DialogProps {
  title?: string | ReactNode
  trigger?: ReactNode
  children?: ReactNode
  forwardedRef?: MutableRefObject<DialogRef>
  size?: DialogSizes
  onDismissDefault?: boolean
  isCloseBtn?: boolean
  onClickCloseButton?: () => void
}

const Dialog = ({
  trigger,
  title,
  children,
  forwardedRef,
  size,
  onDismissDefault,
  isCloseBtn,
  onClickCloseButton,
  ...props
}: DialogProps) => (
  <DialogProvider ref={forwardedRef}>
    {children && trigger && (
      <DialogOpenButton>
        <div>{trigger}</div>
      </DialogOpenButton>
    )}
    <DialogContents
      onClickCloseButton={onClickCloseButton}
      isCloseBtn={isCloseBtn}
      onDismissDefault={onDismissDefault}
      title={title}
      size={size}
      {...props}
    >
      {children}
    </DialogContents>
  </DialogProvider>
)

export { Dialog }
