import { Button } from 'components/ui/button'
import { Spinner } from 'components/ui/spinner'
import { FormEvent, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import tw from 'twin.macro'
import { DialogCloseButton } from './dialogCloseButton'
import { useDialogContext } from './dialogProvider'
/** @jsxImportSource @emotion/react */

export interface DialogContentFormProps {
  textButton?: string
  isLoadingSubmit?: boolean
  isLoadingOptions?: boolean
  children: ReactNode
  extraRightButton?: ReactNode
  onSubmit: (event: FormEvent<HTMLFormElement>, handleCloseDialog: () => void) => void
  hasDivide?: boolean
  noValidate?: boolean
  isDisabledSend?: boolean
  isStep?: boolean
}

const DialogContentForm = ({
  onSubmit,
  children,
  isLoadingSubmit,
  textButton,
  isLoadingOptions,
  extraRightButton,
  noValidate = false,
  isDisabledSend,
  isStep,
  ...props
}: DialogContentFormProps) => {
  const { t } = useTranslation('common')
  const [, setIsOpen] = useDialogContext()
  const isDisabled = isLoadingSubmit ? isLoadingSubmit : isDisabledSend ? isDisabledSend : false
  return (
    <form
      tw="h-full w-full"
      noValidate={noValidate}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(e, () => {
          setIsOpen(false)
        })
      }}
    >
      {isLoadingOptions ? (
        <Spinner isAbsolute={false} />
      ) : (
        <>
          <Wrapper {...props}> {children}</Wrapper>
          {!isStep && (
            <>
              <hr />
              <div tw="flex justify-center py-6">
                <DialogCloseButton>
                  <Button color="gray" type="button" tw="h-12 mr-4" isDisabled={isLoadingSubmit}>
                    {t('buttons.cancel')}
                  </Button>
                </DialogCloseButton>
                <Button
                  tw="h-12"
                  type="submit"
                  color="primary"
                  isDisabled={isDisabled}
                  isLoading={isLoadingSubmit}
                >
                  {textButton || t('buttons.save')}
                </Button>

                {extraRightButton && <div tw="ml-4">{extraRightButton}</div>}
              </div>
            </>
          )}
        </>
      )}
    </form>
  )
}

export { DialogContentForm }

const Wrapper = styled.div(({ hasDivide }: { hasDivide?: boolean }) => [
  hasDivide
    ? tw`grid-cols-1 divide-y divide-gray-600 p-0 gap-0`
    : tw`grid grid-cols-1 gap-4 md:grid-cols-2 p-4 md:(p-6)`
])

export const FormSection = ({
  children,
  className
}: {
  children: ReactNode
  className?: string
}) => (
  <div tw="p-6 grid gap-4 relative" className={className}>
    {children}
  </div>
)
