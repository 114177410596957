import {
  faArrowRightFromBracket,
  faArrowsRotate,
  faEdit,
  faLockKeyhole
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DialogRef } from 'components/dialog/dialogBase/dialogProvider'
import { Arrow } from 'components/ui/arrow'
import { useAuth } from 'context/authProvider'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import React, { MutableRefObject, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
/** @jsxImportSource @emotion/react */
import 'twin.macro'
import tw from 'twin.macro'

interface EmailMenuProps {
  setIsOpenEmailMenu: React.Dispatch<React.SetStateAction<boolean>>
  changePasswordDialogRef: MutableRefObject<DialogRef>
  logoutDialogRef: MutableRefObject<DialogRef>
  isActiveChangeRole: boolean
}

const EmailMenu = ({
  isActiveChangeRole,
  setIsOpenEmailMenu,
  changePasswordDialogRef,
  logoutDialogRef
}: EmailMenuProps) => {
  const { t } = useTranslation('common')
  const { user } = useAuth()

  const containerRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(containerRef, () => {
    setIsOpenEmailMenu(false)
  })

  const isViewEditProfileNav =
    user?.companyStatus == 'active' ||
    user?.companyStatus == 'stripeActivated' ||
    user?.creatorStatus != 'none'

  return (
    <div
      tw="absolute bg-gray-500 z-20 shadow-xl top-0 right-12 transform translate-y-14 xl:translate-y-16 flex flex-col animate-fade-in items-start space-y-1 py-3 rounded-md"
      ref={containerRef}
    >
      <Arrow variant="top" />
      {isViewEditProfileNav && (
        <NavLink
          to={'profile'}
          tw="flex items-center px-6 py-1 text-xs font-bold"
          css={[location.pathname.includes('profile') && tw`text-primary`]}
        >
          <FontAwesomeIcon icon={faEdit} tw="h-4 mr-2" />
          <h1 tw="font-bold">{t(`navigation.profile`)}</h1>
        </NavLink>
      )}
      {isActiveChangeRole && (
        <NavLink
          to={'change-role'}
          tw="flex items-center px-6 py-1 text-xs font-bold"
          css={[location.pathname.includes('change-role') && tw`text-primary`]}
        >
          <FontAwesomeIcon icon={faArrowsRotate} tw="h-4 mr-2" />
          <h1 tw="font-bold">{t(`navigation.change-role`)}</h1>
        </NavLink>
      )}
      <button
        tw="flex items-center px-6 py-1 text-xs font-bold"
        onClick={() => changePasswordDialogRef.current?.setIsOpen(true)}
      >
        <FontAwesomeIcon tw="h-4 mr-2" icon={faLockKeyhole} />
        {t('navigation.changePassword')}
      </button>
      <button
        tw="flex items-center px-6 py-1 text-xs font-bold"
        onClick={() => logoutDialogRef.current?.setIsOpen(true)}
      >
        <FontAwesomeIcon tw="h-4 mr-2" icon={faArrowRightFromBracket} />
        {t('navigation.logout')}
      </button>
    </div>
  )
}

export { EmailMenu }
