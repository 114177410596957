import { Button } from 'components/ui/button'
import { MutableRefObject, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { Dialog } from './dialog'
import { DialogCloseButton } from './dialogCloseButton'
import { DialogRef, useDialogContext } from './dialogProvider'
/** @jsxImportSource @emotion/react */

interface DialogConfirmProps {
  title: string
  confirmTitle?: string
  text: string
  textButton?: string
  isLoading?: boolean
  children?: ReactNode
  forwardedRef?: MutableRefObject<DialogRef>
  onClickConfirmButton: (handleCloseDialog: () => void) => void
  cancelTextButton?: string
}

const DialogConfirm = ({
  title,
  confirmTitle,
  text,
  children,
  forwardedRef,
  textButton,
  isLoading,
  cancelTextButton,
  onClickConfirmButton
}: DialogConfirmProps) => {
  const { t } = useTranslation('common')

  return (
    <Dialog title={title} trigger={children} forwardedRef={forwardedRef}>
      <div tw="h-full p-6">
        <p tw="flex justify-between pb-6 text-[15px] leading-7 whitespace-pre-line">{text}</p>
        <hr tw="-mx-6" />
        <div tw="flex justify-center pt-6 flex-wrap gap-4">
          <DialogCloseButton>
            <Button color="gray" tw="h-10">
              {cancelTextButton || t('buttons.cancel')}
            </Button>
          </DialogCloseButton>
          <ButtonConfirm
            isLoading={isLoading}
            title={confirmTitle ? confirmTitle : title}
            textButton={textButton}
            onClickConfirmButton={onClickConfirmButton}
          />
        </div>
      </div>
    </Dialog>
  )
}

export { DialogConfirm }

const ButtonConfirm = ({
  isLoading,
  title,
  textButton,
  onClickConfirmButton
}: Pick<DialogConfirmProps, 'isLoading' | 'title' | 'textButton' | 'onClickConfirmButton'>) => {
  const [, setIsOpen] = useDialogContext()
  const handleCloseDialog = () => {
    setIsOpen(false)
  }

  return (
    <Button
      color="primary"
      onClick={() => onClickConfirmButton(handleCloseDialog)}
      isLoading={isLoading}
      tw="h-10"
    >
      {textButton || title}
    </Button>
  )
}
