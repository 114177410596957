import { useAuth } from 'context/authProvider'
import { MutableRefObject, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { DialogConfirm } from '../dialogBase/dialogConfirm'
import { DialogRef } from '../dialogBase/dialogProvider'

interface LogoutDialogProps {
  children?: ReactNode
  forwardedRef?: MutableRefObject<DialogRef>
}

const LogoutDialog = ({ children, forwardedRef }: LogoutDialogProps) => {
  const { t } = useTranslation('auth')
  const { logout } = useAuth()

  return (
    <DialogConfirm
      title={t('logout.title')}
      text={t('logout.logoutConfirm')}
      forwardedRef={forwardedRef}
      onClickConfirmButton={(handleCloseDialog) => {
        logout()
        handleCloseDialog()
      }}
    >
      {children}
    </DialogConfirm>
  )
}

export { LogoutDialog }
