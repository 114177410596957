import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ICONS } from 'models/app/icons'
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'
import 'twin.macro'
/** @jsxImportSource @emotion/react */

const DefaultCloseButton = (
  props: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
) => (
  <button
    type="button"
    {...props}
    tw="focus:outline-none text-gray-100 focus:text-primary flex absolute top-4 md:top-6 right-4 md:right-6 p-1 w-6 h-6 z-10"
  >
    <FontAwesomeIcon aria-hidden icon={ICONS.xmark} tw="h-5" />
  </button>
)

export { DefaultCloseButton }
