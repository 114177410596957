import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { LanguageEnum } from 'models/api/languageEnum'
import React from 'react'
import { FieldError } from 'react-hook-form'
import { css } from 'styled-components'
import tw from 'twin.macro'
import { FieldTooltipError } from '../fieldTooltipError'
import { Label } from '../label'
/** @jsxImportSource @emotion/react */

export type TextareaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  label?: string
  icon?: IconDefinition
  error?: FieldError
  language?: LanguageEnum
  isTranslateStepDisable?: boolean
  customMaxLength?: number
}
const focusStyles = css`
  &:focus ~ .icon-name {
    ${tw`text-primary`}
  }
`

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { error, id, label, language, className, isTranslateStepDisable, customMaxLength, ...props },
    ref
  ) => {
    const isMaxLength = !!props.maxLength || !!customMaxLength
    return (
      <div className={className}>
        {label && <Label language={language}>{label}</Label>}
        <div tw="relative flex">
          <textarea
            disabled={props.disabled ? props.disabled : isTranslateStepDisable}
            id={id}
            ref={ref}
            tw="w-full h-44 resize-none text-2xs rounded-md bg-gray-500  text-gray-100 p-4 pb-7 focus:outline-none border border-gray-600 focus:border-primary placeholder-gray-100"
            css={[
              focusStyles,
              error && tw`border-error focus:border-error pr-8`,
              isTranslateStepDisable && tw`bg-gray-800`,
              css`
                ::placeholder {
                  ${tw`text-placeholder`};
                  opacity: 1; /* Firefox */
                }
              `
            ]}
            {...props}
          />
          {isMaxLength && !isTranslateStepDisable && (
            <span tw="absolute bottom-2 right-4 text-gray-200 text-2xs">{`${props.value?.toString()
              .length}/${customMaxLength ? customMaxLength : props.maxLength}`}</span>
          )}
          <FieldTooltipError
            error={error}
            maxLength={customMaxLength ? customMaxLength : props.maxLength}
            minLength={props.minLength}
            isAbsolute
          />
        </div>
      </div>
    )
  }
)

Textarea.displayName = 'AppTextarea'

export { Textarea }
