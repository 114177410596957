import 'twin.macro'
/** @jsxImportSource @emotion/react */

interface HamburgerProps {
  handleToggleIsOpenMobileMenu: () => void
  isOpenMobileMenu: boolean
}

const Hamburger = ({ handleToggleIsOpenMobileMenu, isOpenMobileMenu }: HamburgerProps) => (
  <div tw="pl-3 sx:pl-6 border-l border-gray-600">
    <button
      className={` ${isOpenMobileMenu ? 'menu__button menu__button-active' : 'menu__button'}`}
      tw="flex xl:hidden h-full items-center text-gray-100"
      onClick={handleToggleIsOpenMobileMenu}
    >
      <span className="" />
    </button>
  </div>
)

export { Hamburger }
