import SharedComponentsLayout from 'components/layout/layout'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useAuth } from './context/authProvider'
import 'twin.macro'
/** @jsxImportSource @emotion/react */

const AuthenticatedApp = React.lazy(() => import('./pages/authenticatedApp'))
const UnauthenticatedApp = React.lazy(() => import('./pages/unauthenticatedApp'))
const Cookies = React.lazy(() => import('./components/layout/cookies'))

export function App() {
  const { user } = useAuth()
  const { t, i18n } = useTranslation('common')
  const { pathname } = useLocation()
  const locale = i18n.language
  useEffect(() => {
    document.documentElement.lang = locale
  }, [locale])

  const urlParts = pathname.split('/')
  document.title = `${t(`navigation.${urlParts[1]}` as never)} | refspace`

  return (
    <SharedComponentsLayout>
      <Cookies />
      {user && user?.isEmailVerified ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </SharedComponentsLayout>
  )
}

export default App
