import axios from 'axios'
import { ReactElement, ReactNode } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

interface Props {
  children?: ReactNode
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      queryFn: ({ queryKey }) => {
        if (typeof queryKey === 'string') return axios.get(queryKey).then(({ data }) => data)
        return null
      },
      refetchOnWindowFocus: false
    }
  }
})

const ReactQueryProvider = ({ children }: Props): ReactElement => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
)

export default ReactQueryProvider
