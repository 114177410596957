import { LanguageEnum } from 'models/api/languageEnum'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import 'twin.macro'
import { Tooltip } from '../ui/tooltip'
import { Flag } from './flag'
/** @jsxImportSource @emotion/react */

const languages = ['pl', 'en']

const invalidateQueriesList = [
  'creator/video',
  'creator/product',
  'company/product',
  'company/creator',
  'company/video'
]

const ChangeLanguage = () => {
  const { i18n, ready } = useTranslation('common')
  const queryClient = useQueryClient()
  const handleChangeLanguage = (language: string) => {
    localStorage.setItem('i18nextLng', language)
    i18n.changeLanguage(language, () =>
      invalidateQueriesList.forEach((e) => queryClient.invalidateQueries(e))
    )
  }

  const [viewNavLans, setViewNavLans] = useState(
    languages.filter((value, arrIndex) => {
      return languages.indexOf(i18n.language.slice(0, 2) as 'en') !== arrIndex
    })
  )

  useEffect(() => {
    setViewNavLans(
      languages.filter((value, arrIndex) => {
        return languages.indexOf(i18n.language.slice(0, 2) as 'en') !== arrIndex
      })
    )
  }, [i18n.language.slice(0, 2)])

  return ready ? (
    <Tooltip
      visibleMobile
      trigger={
        <div tw="py-4 px-3 lg:px-5 cursor-pointer min-w-5 min-h-4 ">
          <Flag language={(i18n.language.slice(0, 2) as LanguageEnum) || 'pl-PL'} />
        </div>
      }
      offset={[0, 0]}
      config={{ interactive: true }}
      hasPadding={false}
    >
      <div tw="py-2">
        {viewNavLans.map((language) => (
          <button
            type="button"
            tw="block cursor-pointer pointer-events-auto px-4 py-2"
            key={language}
            onClick={() => handleChangeLanguage(language)}
          >
            <Flag language={language.slice(0, 2)} tw="cursor-pointer" />
          </button>
        ))}
      </div>
    </Tooltip>
  ) : null
}

export { ChangeLanguage }
