import { DialogContentForm } from 'components/dialog/dialogBase/dialogContentForm'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { TextareaControl } from 'components/ui/textarea/textareaControl'
import 'twin.macro'
import { useAdd } from 'hooks/api/useAdd'
import { toastError, toastSuccess } from 'components/ui/toast'
import { useDialogContext } from 'components/dialog/dialogBase/dialogProvider'

interface FormData {
  message: string
}

const ContactUs = () => {
  const { t: tCommon } = useTranslation('common')
  const [, setIsOpen] = useDialogContext()

  const { handleSubmit, control, reset } = useForm<FormData>()
  const { add, isLoadingAdd } = useAdd<FormData>({
    url: 'support/send-mail-to-support',
    onSuccess: () => {
      setIsOpen(false)

      reset()
      toastSuccess(tCommon('info.copyOfTheMessage'))
    },
    onError: () => {
      toastError(tCommon('apiResponse.UnexpectedError'))
    }
  })
  
  const onSubmit = ({ message }: FormData) => {
    add({ message })
  }

  return (
    <DialogContentForm
      textButton={tCommon('buttons.send')}
      isLoadingSubmit={isLoadingAdd}
      onSubmit={handleSubmit(onSubmit)}
      tw="grid-cols-1 mx-6 my-5"
      hasDivide
      noValidate
    >
      <TextareaControl
        label={tCommon('labels.contactUs')}
        placeholder={tCommon('labels.messageContent')}
        name="message"
        control={control}
        maxLength={1000}
        minLength={10}
        rules={{
          required: true,
          minLength: 6,
          maxLength: 1000
        }}
      />
    </DialogContentForm>
  )
}

export default ContactUs
