import { Flag } from 'components/common/flag'
import { LanguageEnum } from 'models/api/languageEnum'
import { DetailedHTMLProps, LabelHTMLAttributes } from 'react'
import 'twin.macro'
/** @jsxImportSource @emotion/react */

const Label = ({
  children,
  language,
  ...props
}: DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> & {
  language?: LanguageEnum
}) => (
  <label tw="flex text-xs font-bold pb-3 items-center" {...props}>
    <>
    {!!language && (
      <div tw="mr-2">
        <Flag language={language} />
      </div>
    )}
    {children}
    </>
  </label>
)

export { Label }
