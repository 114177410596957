import axios from 'axios'
import { AuthExternalLogInRequest } from 'models/api/authExternalLogInRequest'
import { AuthGetUserResponse } from 'models/api/authGetUserResponse'
import {
  ReactNode,
  Suspense,
  createContext,
  lazy,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useAsync } from '../hooks/useAsync'
import { TokenResponse } from '../models/api/tokenResponse'
import * as auth from '../utils/auth'

const bootstrapAppData = async () => {
  let user = null

  const token = await auth.getToken()
  try {
    if (token) {
      const {
        data: { email, userType, companyStatus, creatorStatus, isEmailVerified }
      } = await axios.get<TokenResponse>('auth/user')
      user = {
        email,
        userType,
        companyStatus,
        creatorStatus,
        isEmailVerified
      }
    }
    return user
  } catch (err) {}
}

interface AuthContextType {
  user?: AuthGetUserResponse | null
  login: (form: unknown) => Promise<void>
  loginExternal: (data: AuthExternalLogInRequest) => Promise<void>
  logout: () => void
  refreshUser: () => void
}

const AuthContext = createContext({} as AuthContextType)
AuthContext.displayName = 'AuthContext'

interface AuthProviderProps {
  children: ReactNode
}

const ErrorPage = lazy(() => import('../pages/errorPage'))

function AuthProvider({ children }: AuthProviderProps) {
  const {
    data: user,
    status,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData
  } = useAsync<AuthGetUserResponse>()

  useEffect(() => {
    const appDataPromise = bootstrapAppData()
    run(appDataPromise)
  }, [run])

  const login = useCallback(
    (form: any) =>
      auth.login(form).then(({ email, userType, companyStatus, creatorStatus, isEmailVerified }) =>
        setData({
          email,
          userType,
          companyStatus,
          creatorStatus,
          isEmailVerified
        })
      ),
    [setData]
  )

  const loginExternal = useCallback(
    (form: AuthExternalLogInRequest) => auth.loginExternal(form).then((data) => setData(data)),
    [setData]
  )

  const refreshUser = useCallback(() => bootstrapAppData().then((data) => setData(data)), [setData])
  const history = useNavigate()
  const queryClient = useQueryClient()
  const logout = useCallback(() => {
    auth.logout()
    queryClient.clear()
    setData(null)
    history('/')
  }, [history, queryClient, setData])

  const value = useMemo(
    () => ({ user, login, logout, refreshUser, loginExternal }),
    [login, logout, user, refreshUser, loginExternal]
  )

  if (isLoading || isIdle) {
    return <div />
  }

  if (isError) {
    return (
      <Suspense fallback={<div />}>
        <ErrorPage />
      </Suspense>
    )
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  return context
}

export { AuthProvider, useAuth }
