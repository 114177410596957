import { faHandPaper, faVideoPlus } from '@fortawesome/pro-regular-svg-icons'
import { IconRounded } from 'components/table/tableCells'
import { useDivSize } from 'hooks/useDivSize'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'

/** @jsxImportSource @emotion/react */

export default function StepOne() {
  const { t: tCreator } = useTranslation('creator')
  const { t } = useTranslation('product')
  const { ref, width } = useDivSize<HTMLDivElement>()
  const isWhitespacePreWrap = width <= 375
  return (
    <div ref={ref} tw="pointer-events-none ">
      <div tw="flex px-3 xl:pl-[24px] xl:mt-6 xl:ml-6 items-center h-[64px] flex-shrink-0 sm:pr-13 pr-11 xl:pr-3">
        <p tw="text-xl text-primary font-bold">{t('itemHeader.products')}</p>
      </div>
      <div tw="min-h-[114px] xl:min-h-[40px] xl:pb-2">
        <p tw="text-3xs xl:text-2xs xl:pl-[24px] xl:ml-6 xl:max-w-4xl md:max-w-md px-3 xl:-mt-2">
          {tCreator('onBoarding.products.description')}
        </p>
      </div>
      <div tw="border-t border-transparent pr-0 xl:pr-[52px]">
        <div tw="px-4 flex justify-end items-center pointer-events-none gap-5 xl:h-[96px] h-[113px] relative">
          <div tw="flex flex-row absolute bottom-7 xl:right-5">
            <p tw="text-xs font-semibold">{tCreator('onBoarding.products.addVideo')}</p>
            <img
              tw="relative top-3 -left-8"
              src="/images/onBoarding/arrow-step-one-add-video.svg"
              alt="bonuses"
            />
          </div>
          <IconRounded icon={faVideoPlus} />
        </div>
      </div>
      <div tw="border-t border-transparent pr-0 xl:pr-[52px]">
        <div tw="px-4 flex justify-end items-center pointer-events-none gap-5 xl:h-[96px] h-[113px] relative">
          <div tw="flex flex-row absolute top-4 right-14  gap-1">
            <p css={[isWhitespacePreWrap && tw`whitespace-pre-wrap`]} tw="text-xs font-semibold">
              {tCreator('onBoarding.products.recommendedProduct')}
            </p>
            <img
              tw="relative -top-1"
              src="/images/onBoarding/arrow-step-one-add-recommended.svg"
              alt="bonuses"
            />
          </div>
          <IconRounded icon={faHandPaper} />
        </div>
      </div>
    </div>
  )
}
