import { Control, FieldPath, FieldValues, RegisterOptions, useController } from 'react-hook-form'
import { Textarea, TextareaProps } from './textarea'

type TextareaControlProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>
  control: Control<TFieldValues>
  rules?: RegisterOptions<TFieldValues>
  customMaxLength?: number
} & TextareaProps
const TextareaControl = <TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  rules,
  customMaxLength,
  ...props
}: TextareaControlProps<TFieldValues>) => {
  const {
    field: { value, ...field },
    fieldState
  } = useController({ control, name, rules })
  const valueCustom = (value?.toString()?.replace(/(?<!\r)\n/g, '\r\n') as string) || ''
  const resValue =
    !!props.maxLength && valueCustom.length > props.maxLength
      ? valueCustom.slice(0, props.maxLength)
      : valueCustom

  return (
    <Textarea
      {...props}
      value={resValue}
      {...field}
      error={fieldState.error}
      customMaxLength={customMaxLength}
    />
  )
}

export { TextareaControl }
