import { Portal } from '@reach/portal'
import { Children, cloneElement, isValidElement, ReactNode } from 'react'
import { Config, PopperOptions, usePopperTooltip } from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'
import styled, { css, CSSProperties } from 'styled-components'
import tw, { theme } from 'twin.macro'
/** @jsxImportSource @emotion/react */

type BasePlacement = 'top' | 'bottom' | 'right' | 'left'
type VariationPlacement =
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end'
type AutoPlacement = 'auto' | 'auto-start' | 'auto-end'

type Placement = AutoPlacement | BasePlacement | VariationPlacement

type ColorVariant = 'gray' | 'error'

interface WrapperProps {
  children: ReactNode
  isWithPortal: boolean
}

const Wrapper = ({ children, isWithPortal }: WrapperProps) =>
  isWithPortal ? <Portal>{children}</Portal> : <div>{children}</div>

interface ToolTipStyledProps {
  colorVariant: ColorVariant
  hasPadding: boolean
  withScroll: boolean
  visibleMobile?: boolean
}

const ToolTipStyled = styled.div(
  ({ colorVariant, hasPadding, withScroll, visibleMobile }: ToolTipStyledProps) => [
    tw`p-0! shadow-base! text-2xs invisible lg:visible text-gray-100 border-0! z-50`,
    visibleMobile && tw`visible`,
    withScroll && tw`max-h-72 relative overflow-y-auto`,
    hasPadding && tw`py-3! px-4!`,
    colorVariant === 'gray' && tw`bg-gray-500!`,
    colorVariant === 'error' && tw`bg-error! text-gray-100!`,
    css`
      &.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow::after {
        border-color: transparent transparent ${getColor(colorVariant)} transparent !important;
        top: 1px;
      }
      &.tooltip-container[data-popper-placement*='top'] .tooltip-arrow::after {
        border-color: ${getColor(colorVariant)} transparent transparent transparent !important;
      }
      &.tooltip-container[data-popper-placement*='right'] .tooltip-arrow::after {
        border-color: transparent ${getColor(colorVariant)} transparent transparent !important;
      }
      &.tooltip-container[data-popper-placement*='left'] .tooltip-arrow::after {
        border-color: transparent transparent transparent ${getColor(colorVariant)} !important;
      }
      .tooltip-arrow::before {
        border-width: 0px !important;
      }
    `
  ]
)

const getColor = (colorVariant: ColorVariant) => {
  switch (colorVariant) {
    case 'gray':
      return theme`colors.gray.500`
    case 'error':
      return theme`colors.error`
    default:
  }
}

interface TooltipProps {
  trigger?: ReactNode
  children?: ReactNode
  config?: Config
  popperOptions?: PopperOptions
  colorVariant?: ColorVariant
  hasPadding?: boolean
  offset?: [0 | 10 | 20 | number, number | 0 | 10 | 20]
  placement?: Placement
  isWithPortal?: boolean
  isRelative?: boolean
  styleToolTip?: CSSProperties
  withScroll?: boolean
  visibleMobile?: boolean
  isTruncate?: boolean
}

const Tooltip = ({
  trigger,
  children,
  config,
  popperOptions,
  colorVariant = 'gray',
  hasPadding = true,
  offset,
  placement = 'bottom',
  isWithPortal = true,
  isRelative = false,
  withScroll = false,
  styleToolTip,
  visibleMobile,
  isTruncate,
  ...props
}: TooltipProps) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({ ...config, offset, placement }, popperOptions)
  const fackeCloneElement: any = cloneElement

  return (
    <div css={[isTruncate && tw`truncate`]}>
      {isRelative && isValidElement(trigger) ? (
        fackeCloneElement(Children.only(trigger), {
          ref: setTriggerRef
        })
      ) : (
        <div ref={setTriggerRef} tw="cursor-pointer">
          {trigger}
        </div>
      )}
      {visible && children !== null && (
        <Wrapper isWithPortal={isWithPortal}>
          <ToolTipStyled
            ref={setTooltipRef}
            {...getTooltipProps({
              className: 'tooltip-container',
              style: { borderRadius: '6px', ...styleToolTip }
            })}
            colorVariant={colorVariant}
            hasPadding={hasPadding}
            withScroll={withScroll}
            visibleMobile={visibleMobile}
            {...props}
          >
            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            {children}
          </ToolTipStyled>
        </Wrapper>
      )}
    </div>
  )
}

export { Tooltip }
