import { Button } from 'components/ui/button'
import { UserCreatorStatusEnum } from 'models/api/userCreatorStatusEnum'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { css, keyframes } from 'styled-components'
import tw, { styled } from 'twin.macro'
import StepFoure from './stepFoure'
import StepOne from './stepOne'
import StepThree from './stepThree'
import StepTwo from './stepTwo'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
/** @jsxImportSource @emotion/react */

export default function OnBoarding({
  handleToggleIsOpenMobileMenu,
  creatorStatus,
  isOpenMobileMenu,
  setOpenMobileMenu
}: {
  handleToggleIsOpenMobileMenu: () => void
  creatorStatus?: UserCreatorStatusEnum
  isOpenMobileMenu: boolean
  setOpenMobileMenu: Dispatch<SetStateAction<boolean>>
}) {
  const history = useNavigate()
  const { t } = useTranslation('common')
  const [currentStep, setCurrentStep] = useState(1)
  const totalSteps = 4
  const sliderRef = useRef<Slider>(null)

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep((prev) => prev + 1)
    } else if (totalSteps === currentStep) {
      handleToggleIsOpenMobileMenu()
      creatorStatus != 'active' ? history('/profile') : history('/product')
    }
  }

  useEffect(() => {
    const closeMobileMenu = () => {
      if (isOpenMobileMenu) {
        setOpenMobileMenu(false)
      }
    }
    if (currentStep === 1 || currentStep === 3) {
      currentStep === 1 && history('/product?q=onBoarding')
      closeMobileMenu()
    } else if (currentStep === 2) {
      history('/video?q=onBoarding')
      closeMobileMenu()
    } else if (currentStep === 4 && !isOpenMobileMenu) {
      setOpenMobileMenu(true)
    }
  }, [currentStep, isOpenMobileMenu, history])

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    afterChange: (index: number) => setCurrentStep(index + 1),
    beforeChange: (oldIndex: number, newIndex: number) => {
      if (newIndex === 3 && !checkButton) {
        handleNext()
      }
    },

    arrows: false,
    swipe: true,
    touchMove: true,
    centerMode: false,
    draggable: true,
    touchTreshold: 1
  }
  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }
  const [checkButton, setCheckButton] = useState(false)

  const handleClick = () => {
    goToNextSlide()
    handleNext()
    setCheckButton(true)
  }

  return (
    <div
      css={[
        currentStep === 4 && tw`xl:mt-0 mt-[72px]`,
        tw`xl:w-[calc(100% - 64px)] xl:ml-[64px] w-full absolute z-50`,
        css`
          min-height: calc(100vh);
          background: linear-gradient(180deg, #000000b8 250px, #000000f0 390px, #000000 16%);
        `
      ]}
    >
      <div tw="min-h-[406px] xl:min-h-[298px]">
        <Slider ref={sliderRef} {...settings}>
          <div>
            <StepOne />
          </div>
          <div>
            <StepTwo />
          </div>
          <div>
            <StepThree isStepTwo={currentStep} />
          </div>
          <div>
            <StepFoure />
          </div>
        </Slider>
      </div>

      <div tw="mt-20" css={[tw`xl:mr-16`]}>
        <StepIndicators isStepFoure={currentStep === 4}>
          {[...Array(totalSteps)].map((_, index) => (
            <StepDot key={index} active={index + 1 === currentStep} />
          ))}
        </StepIndicators>
        <Button
          onClick={handleClick}
          tw="xs:py-2 py-1 text-3xs sx:text-2xs xs:text-base mx-auto"
          color={'primary'}
          type="button"
        >
          {totalSteps === currentStep ? t('buttons.close') : t('buttons.next')}
        </Button>
      </div>
    </div>
  )
}

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`

const StepIndicators = styled.div<{ isStepFoure: boolean }>`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  ${(props) => props.isStepFoure && tw`xl:mt-0 -mt-[72px]`}
`

const StepDot = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? '#4CAF50' : '#D4D4D4')};
  margin: 0 5px;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;

  ${(props) =>
    props.active &&
    css`
      animation: ${pulse} 1s ease-in-out infinite;
    `}
`
