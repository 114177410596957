/* eslint-disable @typescript-eslint/no-empty-function */

import axios, { AxiosError } from 'axios'
import { DialogContext } from 'components/dialog/dialogBase/dialogProvider'
import { toastError } from 'components/ui/toast'
import { useContext } from 'react'
import { QueryKey, useMutation, UseMutationOptions, useQueryClient } from 'react-query'

interface Options<TBody> extends UseMutationOptions<unknown, AxiosError, TBody, undefined> {
  invalidateQueriesList?: QueryKey[]
  url: string
  isDisableClosingDialog?: boolean
}

const useAdd = <TBody>(options: Options<TBody> = { url: '' }) => {
  const { onSuccess, url,onError } = options
  const queryClient = useQueryClient()
  const dialogContext = useContext(DialogContext)

  const { mutate, isLoading, ...mutation } = useMutation({
    mutationFn: (body) => axios.post(url, body),
    ...options,
    onSuccess: (...arg) => {
      options?.invalidateQueriesList?.forEach((queryKey) => {
        queryClient.invalidateQueries(queryKey)
      })

      onSuccess?.(...arg)
      if (!options.isDisableClosingDialog) {
        dialogContext?.[1](false)
      }
    },
    
    onError: (...arg) => { 
      toastError
      onError?.(...arg)
    }
  })

  return { add: mutate, isLoadingAdd: isLoading, ...mutation }
}

export { useAdd }
