import axios from 'axios'
import { InputControl } from 'components/ui/input/inputControl'
import { toastSuccess } from 'components/ui/toast'
import { useAsync } from 'hooks/useAsync'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { rgxPassword } from 'utils/validationPatterns'
import { DialogContentForm } from '../dialogBase/dialogContentForm'
/** @jsxImportSource @emotion/react */

type ChangePasswordForm = {
  oldPassword: string
  newPassword: string
  repeatPassword: string
}

const ChangePasswordDialogContent = () => {
  const { t } = useTranslation(['common', 'auth'])
  const { handleSubmit, setError, control, getValues } = useForm<ChangePasswordForm>()
  const { run, isLoading } = useAsync()

  return (
    <DialogContentForm
      textButton={t('auth:changePassword.title')}
      isLoadingSubmit={isLoading}
      onSubmit={(e, handleCloseDialog) => {
        handleSubmit((values) => {
          run(
            axios
              .post('auth/change-password', values)
              .then(() => {
                toastSuccess(t('auth:changePassword.responseSuccess'))
                handleCloseDialog()
              })
              .catch((err: any) => {
                const messageError = err.response?.data?.Message as string

                if (messageError == 'Password incorrect.') {
                  setError('oldPassword', {
                    type: 'manual',
                    message: 'invalidPassword'
                  })
                  return
                }
              })
          )
        })(e)
      }}
      tw="md:grid-cols-2"
    >
      <InputControl
        control={control}
        placeholder={t('auth:form.oldPassword')}
        type="password"
        name="oldPassword"
        rules={{
          required: true,
          pattern: { value: rgxPassword, message: 'password' }
        }}
      />
      <div tw="hidden md:grid" />
      <InputControl
        control={control}
        placeholder={t('auth:form.newPassword')}
        type="password"
        name="newPassword"
        rules={{
          required: true,
          validate: (value) => value !== getValues('oldPassword') || 'passwordMustNotMatch',
          pattern: { value: rgxPassword, message: 'password' }
        }}
      />
      <InputControl
        control={control}
        placeholder={t('auth:form.repeatPassword')}
        type="password"
        name="repeatPassword"
        rules={{
          required: true,
          validate: (value) => value == getValues('newPassword') || 'passwordNotMatch',
          pattern: { value: rgxPassword, message: 'password' }
        }}
      />
    </DialogContentForm>
  )
}

export { ChangePasswordDialogContent }
