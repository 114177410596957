import { LanguageEnum } from 'models/api/languageEnum'
import 'twin.macro'
/** @jsxImportSource @emotion/react */

const Flag = ({ language }: { language: LanguageEnum | string }) => (
  <img
    tw="min-h-4 min-w-5"
    src={`/images/flags/${language.toLowerCase()}.svg`}
    alt=""
    width="22"
    height="16"
  />
)

export { Flag }
