import { faCheck, faInfo, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import styled from 'styled-components'
import tw from 'twin.macro'
/** @jsxImportSource @emotion/react */

const ToastContainerStyled = styled(ToastContainer)`
  .Toastify__toast-container {
    height: 10px;
  }
  .Toastify__toast {
    border-radius: 0;
    min-height: 54px;
  }
  .Toastify__toast--error {
    background-color: #ffffff;
  }
  .Toastify__toast--warning {
    background-color: #ffffff;
  }
  .Toastify__toast--success {
    background-color: #ffffff;
  }
  .Toastify__toast-body {
    min-height: '40px';
  }
`

const Toast = () => (
  <ToastContainerStyled
    position="bottom-left"
    autoClose={6000}
    hideProgressBar
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    icon={false}
    closeButton={false}
    toastStyle={tw`relative flex p-0 items-center overflow-hidden cursor-pointer m-2 max-w-xs w-max`}
    bodyStyle={tw`text-2xs block border-r-0 text-gray-900`}
  />
)

const toastSuccess = (message: string) =>
  toast.success(
    <ToastWrapper>
      <FontAwesomeIcon icon={faCheck} tw="text-primary h-4" />
      {message}
    </ToastWrapper>
  )

const toastError = (message: string) =>
  toast.error(
    <ToastWrapper>
      <FontAwesomeIcon icon={faXmark} tw="text-error h-4" />
      {message}
    </ToastWrapper>
  )

const toastWarning = (message: string) =>
  toast.warning(
    <ToastWrapper>
      <FontAwesomeIcon icon={faInfo} tw="text-warning  h-4" />
      {message}
    </ToastWrapper>
  )

const ToastWrapper = tw.div`grid gap-2 grid-flow-col mx-4 justify-center items-center text-2xs font-bold whitespace-pre-line`

export { Toast, toastSuccess, toastError, toastWarning }
