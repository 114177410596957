/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosResponse } from 'axios'
import { toastSuccess } from 'components/ui/toast'
import i18n from 'i18n'
import { AuthExternalLogInRequest } from 'models/api/authExternalLogInRequest'
import { TokenResponse } from '../models/api/tokenResponse'

const tokenKey = 'token'
const refreshTokenKey = 'refreshToken'

const getToken = async () => localStorage.getItem(tokenKey)
const getRefreshToken = async () => localStorage.getItem(refreshTokenKey)

const handleUserResponse = (res: AxiosResponse<TokenResponse>) => {
  localStorage.setItem(tokenKey, res?.data?.token || '')
  localStorage.setItem(refreshTokenKey, res?.data?.refreshToken || '')
  axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`

  if (res.data.isEmailVerified) {
    toastSuccess(i18n.t('auth:login.loginSuccess'))
  }

  return res.data
}

const login = ({ email, password }: { email: string; password: string }) =>
  axios.post<TokenResponse>('auth/log-in', { email, password }).then(handleUserResponse)

const loginExternal = async (data: AuthExternalLogInRequest) =>
  axios.post<TokenResponse>('auth/log-in/external', data).then(handleUserResponse)

const logout = async () => {
  axios.defaults.headers.common.Authorization = 'Bearer'
  localStorage.removeItem(tokenKey)
  localStorage.removeItem(refreshTokenKey)
}

export { getRefreshToken, getToken, login, loginExternal, logout, refreshTokenKey, tokenKey }
