import {
  faBox,
  faBoxCheck,
  faMoneyBill1,
  faUserLarge,
  faVideo
} from '@fortawesome/pro-regular-svg-icons'
import { useAuth } from 'context/authProvider'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import 'twin.macro'
import tw from 'twin.macro'
import { CloudMenu } from './cloudMenu'
import { SideBarItem, WrapperSideBarItem } from './sideBarItem'
/** @jsxImportSource @emotion/react */

export const navigationList = [
  {
    type: ['company'],
    icon: faBox,
    path: 'product'
  },
  {
    type: ['company'],
    icon: faUserLarge,
    path: 'creator'
  },
  {
    type: ['creator'],
    icon: faBox,
    path: 'product'
  },
  {
    type: ['creator', 'company'],
    icon: faVideo,
    path: 'video'
  },
  {
    type: ['creator'],
    icon: faMoneyBill1,
    path: 'finances'
  },
  {
    type: ['company'],
    icon: faBoxCheck,
    path: 'order'
  }
]

const SideBar = ({ isViewOnboarding }: { isViewOnboarding: boolean }) => {
  const { t } = useTranslation('common')
  const { user } = useAuth()
  const { pathname } = useLocation()

  const userType =
    user?.userType === 'creatorAndCompany'
      ? window.localStorage.getItem('userType')
      : user?.userType

  const [isTuch, setIsTuch] = useState(false)
  useEffect(() => {
    if (isTuch) {
      setIsTuch(false)
    }
  }, [pathname])

  return (
    <div
      tw="hidden xl:block w-16 fixed top-16 left-0 h-nav-screen bg-gray-800 py-2 z-10"
      css={[isViewOnboarding && tw`pointer-events-none`]}
    >
      {((userType === 'creator' && user?.creatorStatus != 'none') ||
        (userType === 'company' && user?.companyStatus === 'active')) &&
        navigationList.map(({ icon, path, type }, index) => (
          <React.Fragment key={index}>
            {type.find((item) => userType === item) && (
              <SideBarItem
                pathname={pathname}
                isTuch={isTuch}
                setIsTuch={setIsTuch}
                icon={icon}
                path={path}
                index={index}
              />
            )}
          </React.Fragment>
        ))}
      {userType === 'creator' && user?.creatorStatus != 'none' && (
        <WrapperSideBarItem
          onTouchEnd={() =>
            setTimeout(() => {
              setIsTuch(true)
            }, 3000)
          }
          isTuch={isTuch}
          to={'/bonuses'}
        >
          {pathname.includes('bonuses') ? (
            <img src="/images/icons/bonuses-green.svg" alt="bonuses-green" />
          ) : (
            <img src="/images/icons/bonuses.svg" alt="bonuses" />
          )}
          <CloudMenu pageName={t(`navigation.bonuses` as never)} />
        </WrapperSideBarItem>
      )}
    </div>
  )
}

export default SideBar
