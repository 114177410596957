import { useEffect, useRef, useState } from 'react'

const useDivSize = <TRef extends Element>() => {
  const ref = useRef<TRef>(null)

  const [size, setSize] = useState({
    width: 0,
    height: 0,
    scrollHeight: 0
  })

  useEffect(() => {
    const resizeObserver = new ResizeObserver(([entry]) => {
      requestAnimationFrame(() => {
        if (!entry) {
          return
        }
        const rect = entry.target.getBoundingClientRect()
        setSize({
          width: rect.width,
          height: rect.height,
          scrollHeight: entry.target.scrollHeight || 0
        })
      })
    })

    if (ref.current) {
      resizeObserver.observe(ref.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return { ref, ...size }
}

export { useDivSize }
