import { Arrow } from 'components/ui/arrow'
import 'twin.macro'
/** @jsxImportSource @emotion/react */

const CloudMenu = ({ pageName }: { pageName: string }) => {
  return (
    <div tw="absolute top-0 right-0 transform translate-x-full shadow-base font-bold hidden bg-gray-500 w-max flex-col">
      <div tw="flex absolute p-4 space-x-4 items-center justify-self-start bg-gray-500 whitespace-nowrap rounded-md shadow-base">
        {pageName} <Arrow variant="left" />
      </div>
    </div>
  )
}

export { CloudMenu }
