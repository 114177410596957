import i18n from 'i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['pl', 'en'],
    lng: 'pl',
    ns: [],
    fallbackLng: 'pl',
    interpolation: {
      escapeValue: false
    },
    debug: false,
    react: {
      useSuspense: true
    }
  })

export default i18n
