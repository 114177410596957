import { useTranslation } from 'react-i18next'
import 'twin.macro'
import { css, keyframes } from 'styled-components'
import tw, { styled } from 'twin.macro'

/** @jsxImportSource @emotion/react */

export default function StepThree({ isStepTwo }: { isStepTwo?: number }) {
  const { t: tCreator } = useTranslation('creator')
  const { t } = useTranslation('product')

  return (
    <>
      <div tw="xl:block hidden max-h-[298px]">
        <div tw="flex px-3 xl:pl-[24px] xl:mt-6 xl:ml-6 items-center h-16 justify-between flex-shrink-0 sm:pr-13 pr-11 xl:pr-3 opacity-0">
          <p tw="text-xl text-primary font-bold">{t('itemHeader.promotionalVideos')}</p>
        </div>

        <p tw="text-3xs xl:text-2xs xl:pl-[24px] xl:ml-6 xl:max-w-4xl md:max-w-md max-w-[220px] opacity-0">
          {tCreator('onBoarding.video.description')}
        </p>

        <div
          css={[
            tw`relative flex flex-row items-end mt-[100px] -ml-14 transition-all duration-300 `,
            isStepTwo === 2 && tw`opacity-0 !duration-[0ms]`
          ]}
        >
          <img
            src="/images/onBoarding/desktop/arrow-step-three.svg"
            alt="bonuses"
            tw="h-[110px] px-5 "
          />
          <p tw="text-xs font-semibold px-4 whitespace-pre-wrap -mb-5 -ml-5">
            {tCreator('onBoarding.navigation')}
          </p>
        </div>
      </div>

      <div tw="xl:hidden min-h-[376px] flex flex-col items-end">
        <img src="/images/onBoarding/arrow-step-three.svg" alt="bonuses" tw="h-[130px] px-5" />
        <p tw="text-xs font-semibold px-4 whitespace-pre-wrap">
          {tCreator('onBoarding.navigation')}
        </p>
      </div>
    </>
  )
}
