import { ChangeEvent, FocusEvent } from 'react'
import { Control, FieldPath, FieldValues, RegisterOptions, useController } from 'react-hook-form'
import { Input, InputProps } from './input'

type InputControlProps<TFieldValues extends FieldValues = FieldValues> = {
  name: FieldPath<TFieldValues>
  control: Control<TFieldValues>
  rules?: RegisterOptions<TFieldValues>
  isDisableColorInputTextValue?: boolean
  isTranslateStepDisable?: boolean
  isTranslateStep?: boolean
  handleRemoveItemFieldArray?: () => void
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement, Element>) => void
} & InputProps

const InputControl = <TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  rules,
  isDisableColorInputTextValue,
  hideMax,
  handleRemoveItemFieldArray,
  isTranslateStepDisable,
  isTranslateStep,
  onChange,
  onBlur,
  ...props
}: InputControlProps<TFieldValues>) => {
  const {
    field: { value, onChange: onChangeControl, onBlur: onBlurControl, ...field },
    fieldState
  } = useController({ control, name, rules })

  return (
    <>
      <Input
        handleRemoveItemFieldArray={handleRemoveItemFieldArray}
        value={value !== undefined && value !== null ? String(value) : ''}
        hideMax={hideMax}
        onChange={(onChangeValue) => {
          onChangeControl(onChangeValue)
          onChange?.(onChangeValue)
        }}
        onBlur={(event) => {
          onBlurControl()
          onBlur?.(event)
        }}
        {...props}
        {...field}
        error={fieldState.error}
        isDisableColorInputTextValue={isDisableColorInputTextValue}
        isTranslateStepDisable={isTranslateStepDisable}
        isTranslateStep={isTranslateStep}
      />
    </>
  )
}

export { InputControl }
