import { faBell } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { useAuth } from 'context/authProvider'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { NotificationPaginatedResponse } from 'models/api/notificationPaginatedResponse'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useInfiniteQuery } from 'react-query'
/** @jsxImportSource @emotion/react */
import 'twin.macro'
import tw from 'twin.macro'
import { getColor } from 'utils/renderHelpers'
import { NotificationMenu } from './notificationMenu'

interface NotificationProps {
  handleCloseMobileMenu: () => void
  setIsOpenNotificationMenu: Dispatch<SetStateAction<boolean>>
  isOpenNotificationMenu: boolean
}

const Notification = ({
  setIsOpenNotificationMenu,
  handleCloseMobileMenu,
  isOpenNotificationMenu
}: NotificationProps) => {
  const [notificationCount, setNotificationCount] = useState(0)
  const { user } = useAuth()
  const isCompany = user?.userType == 'company'

  const {
    isLoading,
    refetch: refetchNotifications,
    data,
    isFetching,
    hasNextPage,
    fetchNextPage,
    remove
  } = useInfiniteQuery(
    [isCompany ? 'notifications-company' : 'notifications-creator'],
    async ({ pageParam = 1 }) => {
      const dat = (
        await axios.get<NotificationPaginatedResponse>(
          isCompany
            ? `notification/company?pageNumber=${pageParam}`
            : `notification/creator?pageNumber=${pageParam}`
        )
      ).data
      setNotificationCount(dat.unreadNotificationsCount)
      return dat
    },
    {
      refetchInterval: 200000,
      enabled: user?.userType != 'fan',
      getNextPageParam: (lastPage, allPages) => {
        return allPages.length < lastPage.totalPages ? lastPage.currentPage + 1 : undefined
      },
      select: (d) => {
        return { pageParams: d.pageParams, pages: d.pages.flatMap((e) => e.items) }
      }
    }
  )

  useEffect(() => {
    if (!isOpenNotificationMenu && data) {
      remove()
      refetchNotifications()
    }
  }, [isCompany, isOpenNotificationMenu])

  const containerRef = useRef<HTMLDivElement>(null)
  useOnClickOutside(containerRef, () => {
    setIsOpenNotificationMenu(false)
  })

  const onClick = () => {
    setIsOpenNotificationMenu(!isOpenNotificationMenu)
    handleCloseMobileMenu()
  }

  const menuContainerRef = useRef<HTMLDivElement>(null)

  const onScroll = () => {
    if (menuContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = menuContainerRef.current
      if (scrollHeight - scrollTop <= clientHeight * 2 && !isFetching && data && hasNextPage) {
        fetchNextPage()
      }
    }
  }

  return (
    <div ref={containerRef} tw="ml-3 xs:mr-4 mr-0">
      <button
        tw=" flex items-center justify-center"
        css={notificationCount > 0 ? tw`bg-primary rounded-full w-[28px] h-[28px]` : tw``}
        onClick={onClick}
      >
        {notificationCount > 0 ? (
          <img src="/images/icons/bell-on.svg" alt="bell-on" tw="h-4" />
        ) : (
          <FontAwesomeIcon icon={faBell} tw="h-5 justify-self-center" color={getColor('white')} />
        )}
      </button>

      {isOpenNotificationMenu && (
        <NotificationMenu
          data={data?.pages}
          isCompany={isCompany}
          isOpenNotificationMenu={isOpenNotificationMenu}
          setIsOpenNotificationMenu={setIsOpenNotificationMenu}
          isLoading={isLoading}
          refetchNotifications={refetchNotifications}
          containerRef={menuContainerRef}
          onScroll={onScroll}
        />
      )}
    </div>
  )
}

export { Notification }
