import { useTranslation } from 'react-i18next'
import 'twin.macro'

/** @jsxImportSource @emotion/react */

export default function StepFoure() {
  const { t: tCreator } = useTranslation('creator')

  return (
    <div tw="xl:min-h-[410px] min-h-[422px] flex flex-col items-end -mt-4 pointer-events-none">
      <img src="/images/onBoarding/arrow-step-foure.svg" alt="bonuses" tw="h-[100px] pr-20" />
      <p tw="text-xs font-semibold pr-10 whitespace-pre-wrap">{tCreator('onBoarding.profile')}</p>
    </div>
  )
}
// xl:min-h-[410px] min-h-[422px]
