import { Children, cloneElement, isValidElement, ReactNode } from 'react'
import { callAll } from '../../../utils/callAll'
import { useDialogContext } from './dialogProvider'

interface DialogButtonProps {
  children: ReactNode
}

const DialogCloseButton = ({ children }: DialogButtonProps) => {
  const [, setIsOpen] = useDialogContext()
  const fackeCloneElement: any = cloneElement
  return isValidElement(children)
    ? fackeCloneElement(Children.only(children), {
        onClick: callAll(() => setIsOpen(false), children.props.onClick)
      })
    : null
}

export { DialogCloseButton }
