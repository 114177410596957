import axios from 'axios'
import { toastError } from './components/ui/toast'
import { apiErrorsTranslations } from './utils/apiErrorsTranslations'
import { logout } from './utils/auth'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
axios.defaults.headers.common.language = localStorage.getItem('i18nextLng')?.slice(0, 2) as string
axios.defaults.headers.common.Area = 'Web'

let isRefreshing = false
let failedQueue: {
  resolve: (token: string | null) => void
  reject: (error: unknown) => void
}[] = []

const processQueue = (error: unknown, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status >= 500) {
      // todo isWork??
      toastError(apiErrorsTranslations.UnexpectedError)
      return Promise.reject(error)
    }

    if (!error.response) {
      return
    }

    const errorMessageKey = error.response.data.Message
    if (errorMessageKey && apiErrorsTranslations[errorMessageKey]) {
      toastError(apiErrorsTranslations[errorMessageKey])
    }

    if (
      error.response?.data?.Error === 'AccountMarkedForDeletion' ||
      error.response?.data?.Error === 'AccountBlocked'
    ) {
      return Promise.reject(error)
    }

    if (error.config?.url.includes('refresh')) {
      if (error.response?.status === 400) {
        window.location.reload()
        logout()
      }
    }

    const originalRequest = error.config
    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            return axios(originalRequest)
          })
          .catch((err) => Promise.reject(err))
      }

      originalRequest._retry = true
      isRefreshing = true

      const refreshToken = window.localStorage.getItem('refreshToken')
      const token = window.localStorage.getItem('token')
      return new Promise((resolve, reject) => {
        axios
          .post('auth/refresh', { token, refreshToken })
          .then(({ data }) => {
            window.localStorage.setItem('token', data.token)
            window.localStorage.setItem('refreshToken', data.refreshToken)
            axios.defaults.headers.common.Authorization = `Bearer ${data.token}`
            originalRequest.headers.Authorization = `Bearer ${data.token}`
            processQueue(null, data.token)
            resolve(axios(originalRequest))
          })
          .catch((err) => {
            processQueue(err, null)
            reject(err)
          })
          .finally(() => {
            isRefreshing = false
          })
      })
    }

    return Promise.reject(error)
  }
)
