/** @jsxImportSource @emotion/react */
import { ChangeLanguage } from 'components/common/changeLanguage'
import { Logo } from 'components/layout/authenticated/topBar/topBar'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import 'twin.macro'

const TopBar = () => {
  const { t } = useTranslation('common')
  return (
    <div tw="w-full fixed top-0 z-50 flex bg-gray-800 drop-shadow-sm">
      <Logo />

      <div tw="w-full flex justify-between items-center pl-3 pr-6">
        <Link to="/login">
          <p tw="text-gray-100 text-lg font-bold">{t('header.panelForUsers')}</p>
        </Link>
        {process.env.REACT_APP_IS_STAGING == 'true' && (
          <p tw="text-xs font-semibold text-warning">STAGING</p>
        )}
        <ChangeLanguage />
      </div>
    </div>
  )
}

export { TopBar }
