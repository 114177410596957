import { ReactElement, ReactNode } from 'react'
import { Toast } from '../components/ui/toast'
import { AuthProvider } from './authProvider'
import ReactQueryProvider from './reactQueryProvider'

interface AppProvidersProps {
  children?: ReactNode
}

const AppProviders = ({ children }: AppProvidersProps): ReactElement => (
  <ReactQueryProvider>
    <Toast />
    <AuthProvider>{children}</AuthProvider>
  </ReactQueryProvider>
)

export default AppProviders
